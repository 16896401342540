<template>
  <div>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <label>entries</label>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              <b-button :to="{
                name: 'add-new-client',
                params: {
                  addNew: true
                }
              }" variant="primary">
                <span class="text-nowrap">Add Client</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <Loader v-if="state.loading" />
    <b-card style="padding:0rem" v-else>
      <b-card-body style="padding:0rem">
        <b-table class="position-relative" style="text-align: center;padding: 0rem;" :items=filteredInvoices responsive
          :fields="invoiceListFields" primary-key="id" :busy="state.loading" show-empty
          empty-text="No matching records found">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <loader />
            </div>
          </template>
          <template #cell(image)="data">
            <b-media vertical-align="center">
              <template #aside>
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                <img style="height: 100px;width: 100px;" :src="data.item.ClientSetting.logo" />
              </span>
            </b-media>
          </template>
          <template #cell(invoiceNumber)="data">
            <b-link :to="{
              name: 'settings-page',
              params: {
                id: data.item.invoiceGuid
              }
            }">
              <span>{{ data.item.invoiceNumber }}</span>
            </b-link>
          </template>

          <template #cell(actions)="data">
            <b-button type="submit" variant=".btn-flat-dark" :to="{
              name: 'edit-client',
              params: {
                id: data.item.ClientSetting.ClientId
              }
            }">
              <edit-icon size="1.5x" class="custom-class text-primary"></edit-icon>
            </b-button>
            <!-- <b-button variant=".btn-flat-dark" >
              <trash-2-icon
                size="1.5x"
                class="custom-class text-danger"
              ></trash-2-icon>
            </b-button> -->
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </div>

</template>


<script>
import {
  BTable,
  BCol,
  BRow,
  BFormInput,
  BButton,
  BCard,
  BCardBody,
  BMedia,
  BAvatar,
  BFormSelect,
  BLink,
  BFormFile,
  BCardText,
  BMediaBody,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import Loader from "../e-commerce/components/Loader.vue";
import Swal from "sweetalert2";
import axios from "axios";
import {
  Trash2Icon,
  EditIcon,
  EyeOffIcon,
  XCircleIcon,
  DownloadIcon,
  EyeIcon,
  ExternalLinkIcon,
} from "vue-feather-icons";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import useClient from "./useClient";

export default {
  components: {
    BTable,
    BCol,
    BLink,
    XCircleIcon,
    DownloadIcon,
    BAvatar,
    BMedia,
    BRow,
    vSelect,
    BFormInput,
    BButton,
    BFormSelect,
    BCardBody,
    BCard,
    BFormFile,
    BCardText,
    BMediaBody,
    BForm,
    Loader,
    Trash2Icon,
    EditIcon,
    EyeOffIcon,
    EyeIcon,
    ExternalLinkIcon,
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  created() { },
  async mounted() {

  },
  computed: {
    filteredInvoices() {
      if (!this.searchQuery) {
        return this.state.invoices;
      }
      return this.state.invoices.filter((invoice) =>
        invoice.title
          ?.toLowerCase()
          .includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {},

  setup() {
    const { invoiceListFields, listItem, state, } = useClient();
    const isVisible = ref(false);

    return {
      invoiceListFields,
      listItem,
      state,
      isVisible,

    };
  },
};
</script>
<style lang="scss">
.swal2-confirm {
  margin-right: 10px !important;
}
</style>